import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import OrderPropisOne from '../components/orderPropisOne'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Images for page
import ImagePropis from "../images/russian.jpg"

// Images for carousel
import russian1 from '../images/russian-1.jpg'
import russian2 from '../images/russian-2.jpg'
import russian3 from '../images/russian-3.jpg'
import russian4 from '../images/russian-4.jpg'
import russian5 from '../images/russian-5.jpg'
import russian6 from '../images/russian-6.jpg'

const imgRussian = [
  {
    id: 1,
    pic: russian1
  },
  {
    id: 2,
    pic: russian2
  },
  {
    id: 3,
    pic: russian3
  },
  {
    id: 4,
    pic: russian4
  },
  {
    id: 5,
    pic: russian5
  },
  {
    id: 6,
    pic: russian6
  },
]

const PropisOne = () => {
  const imageMap = imgRussian.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Layout>
      <Seo title="Прописи русский язык" />
      <div className="mb-4">
            <a href="/">
              <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
                На главную
              </button>
            </a>
        </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} />
        </div> 
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи русский язык
          </h1>
          <span className="mt-12">
          <p>Прописи - большая рабочая тетрадь для овладения навыками письма. Подойдет для школьников начальных классов  и взрослых, которые хотят улучшить свой почерк.  С данным учебным пособием ваши навыки письма  увеличатся.</p>
          <p>Этот сборник упражнений поможет вам освоить письменную речь. Вы пропишите ряд простых практических заданий , где вы последовательно научитесь:</p>         
          <span>- письменным маленьким буквам;</span> <br />         
          <span>- слогам;</span><br />
          <span>- словам из 3 и 4 букв;</span><br />
          <span>- большим прописным буквам;</span><br />
          <span>- словам с большой буквы;</span><br />
          <span className="mb-6">- алфавиту.</span><br /><br />
          <span>Наша пропись это лучший сборник упражнений для практики последовательного становления письма!</span><br />
          <span>Вы найдете строчки с пунктиром и пустые, для самостоятельной работы.</span><br />
          <span>Вы будете точно знать как соединять каждую букву, потому что слогам с простыми соединениями вы уделите большую часть рабочей тетради.</span><br />
          <span>Прописав данную пропись вы останетесь довольны результатом!!!</span>
          </span>
        </div>            
      </div>
      <div>
          <OrderPropisOne/> 
        </div> 
      <div>
      <div className="mt-24 mb-24">
        <Slider {...settings}>
          {imageMap}
        </Slider>
      </div> 
      </div>
    </Layout>
    
  )
}

export default PropisOne