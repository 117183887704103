import React from "react"
import Ozon from "../images/ozonme.png"
import Wild from "../images/wildberries.png"
import Kazan from "../images/kazan.png"

const OzonLink =
  "https://www.ozon.ru/product/propisi-rabochaya-tetrad-russkiy-yazyk-avtor-ne-ukazan-327595175/?_bctx=CAQQjtMN&asb2=JCXaL9AnCQOnl8uVFrO5yvFxNz4G0bomkQnLTaT9VTl1CJBNIdbiQE4K_qwBkXy1&hs=1&sh=f8T_Z4Xx"
const Wildberries =
  "https://www.wildberries.ru/catalog/42949806/detail.aspx?targetUrl=BP"
const KazanExpress =
  "https://kazanexpress.ru/product/Propisi-Propisi-russkij-yazyk-Rabochaya-tetrad-1058059"

function OrderPropisOne() {
  return (
    <>
      <div class="bg-gray-100 mt-12">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 class="mb-20 text-2xl font-black tracking-tighter text-black ml-0 md:ml-12 md:text-5xl title-font">
              Заказать
            </h2>

            <div class="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
              <div class="group relative">
                <a href={OzonLink} target="_blank" rel="noopener noreferrer">
                  <div class="flex justify-center relative w-full bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 lg:aspect-h-1">
                    <img className="w-28 mt-4" src={Ozon} alt="ozon" />
                  </div>
                </a>
              </div>

              <div class="group relative">
                <a href={Wildberries} target="_blank" rel="noopener noreferrer">
                  <div class="flex justify-center relative w-full bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      className="w-44 m-0 pt-2 pb-3"
                      src={Wild}
                      alt="wildberries"
                    />
                  </div>
                </a>
              </div>

              <div class="group relative">
                <a
                  href={KazanExpress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="flex justify-center relative w-full bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      className="w-44 m-0 pb-1"
                      src={Kazan}
                      alt="kazanexpress logo"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderPropisOne
